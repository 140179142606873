import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../css/style.css";
import "../css/newcss.css";
import "./bootstrap.min.css";
import axios from "axios";

let start = "";
let end = "";

const JkMotelbooking = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    rooms: "",
    stdate: "",
    endate: "",
    nonight: "",
    stnam: "",
    noadult: "",
    nochild: "",
    add1: "",
    city: "",
    cnam: "",
  });

  var today = new Date().toISOString().split("T")[0];
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  // const [contactData, setContactData] = useState({ ...initialFormState });
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const stnam = e.stnam;
    const value1 = e.target.value;
    setData({ ...data, [name]: value, [stnam]: value1 });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // axios
    //   .post("https://jkgroupaurg.com//api/users/create", {
    //     ...data,
    //     recaptchaValue,
    //   })
    //   .then((res) => alert("User created!"))
    //   .catch((error) => alert(error.response.data.message));

    console.log(data);
    // setContactData({ ...initialFormState });
  };

  const [users, setUser] = useState([]);
  const [req] = useState({
    stateName: "",
    cityName: "",
  });
  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const result = await axios.get(
      "https://ebssoftware.co.in/jkgroup/getdata.php"
    );
    setUser(result.data.reverse());
  };

  const getdays = (e) => {
    //end = document.getElementById("stdate");
    var end = data.endate;
    var start = data.stdate;
    start = new Date(start);
    end = new Date(end);
    e.target.nonight = (end - start) / 1000 / 60 / 60 / 24;
    document.getElementById("nonight").value = e.target.nonight;
    data.nonight = e.target.nonight;
  };

  const weendays = (e) => {
    end = e.target.value;
    start = new Date(start);
    end = new Date(end);
    e.target.nonight = (end - start) / 1000 / 60 / 60 / 24;
    document.getElementById("nonight").value = e.target.nonight;
  };
  const betweendays = (e) => {
    start = e.target.value;
  };
  const selectRoom = (e) => {
    let name = e.target.name;
    data.stnam = e.target.name;
    let value = e.target.value;
    req[name] = value;
    data.rooms = value;

    // var data = value;
  };

  const selectState = (e) => {
    let name = e.target.name;
    data.stnam = e.target.name;
    let value = e.target.value;
    req[name] = value;
    data.stnam = value;

    // var data = value;
  };
  return (
    <body>
      <div className="container-fluid bg-dark p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-map-marker-alt text-primary me-2"></small>
              <small>MG Road, Ramesh Chowk, Aurangabad-Bihar - 824101</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-phone-alt text-primary me-2"></small>
              <small>+91 7942602014</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-facebook-f"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-twitter"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-linkedin-in"></i>
                </NavLink>
              </div>
              <div className="btn btn-square btn-link rounded-0">
                <NavLink to="/">
                  <i className="fab fa-instagram"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <NavLink
          to="/"
          className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5"
        >
          <h2 className="m-0 text-primary">Hotel JK</h2>
        </NavLink>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink to="/JkIndex" className="nav-item nav-link active">
              Home
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkhotelRooms"
            >
              Rooms
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelGalary"
            >
              Galary
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelabout"
            >
              About us
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelcontact"
            >
              Contact us
            </NavLink>
          </div>
          <div
            className="nav-item nav-link"
            style={{
              backgroundColor: "#b76609",
              color: "white",
              fontWeight: "bold",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            <NavLink to={"/jkbooking"} style={{ color: "white" }}>
              {" "}
              Book the Room
              <i className="fa fa-arrow-right ms-3"></i>
            </NavLink>
          </div>
        </div>
      </nav>

      <Container fluid>
        <Row>
          <div
            className="container h-100"
            style={{ border: "1px", borderColor: "green" }}
          >
            <div
              className="row h-100 justify-content-center align-items-center"
              style={{ border: "1px", borderColor: "green" }}
            >
              <div
                className="col-12 col-md-10 col-lg-6"
                style={{ border: "1px", borderColor: "green" }}
              >
                <h2>Booked the Room</h2>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6 form-group">
                      <label htmlFor="name">Name:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        value={data.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label htmlFor="email">Email:</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={data.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 form-group">
                      <label htmlFor="name">Phone No:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        value={data.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <p>Room Type:</p>
                      <select
                        onChange={selectRoom}
                        name="rooms"
                        id="rooms"
                        className="box form-control"
                        required
                        style={{ backgroundColor: "white", margin: "-2%" }}
                      >
                        <option value="">Select a Type of Room</option>
                        <option value="Super Delux">Super Delux</option>
                        <option value="Delux">Delux</option>
                        <option value="Standard">Standard</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 form-group">
                      <label htmlFor="name">Checkin Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="stdate"
                        min={today}
                        value={data.stdate}
                        onChange={handleChange}
                        onBlur={betweendays}
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label htmlFor="email">Checkout Date</label>
                      <input
                        type="date"
                        className="form-control"
                        name="endate"
                        min={today}
                        value={data.endate}
                        required
                        onChange={handleChange}
                        onBlur={weendays}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-sm-6 form-group"
                      style={{ display: "none" }}
                    >
                      <label htmlFor="name">No of Nights</label>
                      <input
                        type="number"
                        className="form-control"
                        name="nonight"
                        id="nonight"
                        value={data.nonight}
                        onChange={handleChange}
                        required
                        readOnly="true"
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label htmlFor="email">Nationality</label>
                      <select
                        onChange={selectState}
                        name="stnam"
                        id="stnam"
                        className="box form-control"
                        required
                        style={{ backgroundColor: "white" }}
                      >
                        <option value={"604"}>{"Indian"}</option>
                        {users.map((user, index) => (
                          <option value={user.id}>{user.nationality}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 form-group">
                      <label htmlFor="name">No of Adult</label>
                      <input
                        type="number"
                        className="form-control"
                        name="noadult"
                        value={data.noadult}
                        onChange={handleChange}
                        onBlur={getdays}
                        onClick={getdays}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 form-group">
                      <label htmlFor="email">No of Child</label>
                      <input
                        type="number"
                        className="form-control"
                        name="nochild"
                        value={data.nochild}
                        onChange={handleChange}
                        onBlur={getdays}
                        onAuxClick={getdays}
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label htmlFor="email">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="add1"
                        value={data.nochild}
                        onChange={handleChange}
                        onBlur={getdays}
                        onAuxClick={getdays}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 form-group">
                      <label htmlFor="email">City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={data.city}
                        onChange={handleChange}
                        onBlur={getdays}
                        onAuxClick={getdays}
                        required
                      />
                    </div>
                    <div className="col-sm-6 form-group">
                      <label htmlFor="email">State</label>
                      <input
                        type="text"
                        className="form-control"
                        name="cnam"
                        value={data.cnam}
                        onChange={handleChange}
                        onBlur={getdays}
                        onAuxClick={getdays}
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="row">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LfZKQspAAAAAOUrVst9fK4vTK64ShgOhZ7Yiol4"
                      onChange={handleChange}
                    />
                  </div> */}

                  <div className="row mt-2">
                    <div className="col-sm-3 form-group">
                      <button type="submit" className="btn btn-primary">
                        Send you Query
                      </button>
                    </div>
                    <div className="col-sm-4 form-group">
                      <button type="submit" className="btn btn-success">
                        Book & Pay Online
                      </button>
                    </div>
                  </div>
                  <br />
                  <br />
                </form>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </body>
  );
};

export default JkMotelbooking;
