import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../css/style.css";
import "../css/newcss.css";
import "./bootstrap.min.css";
import web5 from "../img/cntr.png";
import h5 from "../img/new5.png";

const JkHotelcontact = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth state on window resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <body>
      <div className="container-fluid bg-dark p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-map-marker-alt text-primary me-2"></small>
              <small>MG Road, Ramesh Chowk, Aurangabad-Bihar - 824101</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-phone-alt text-primary me-2"></small>
              <small>+91 7942602014</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-facebook-f"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-twitter"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-linkedin-in"></i>
                </NavLink>
              </div>
              <div className="btn btn-square btn-link rounded-0">
                <NavLink to="/">
                  <i className="fab fa-instagram"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <NavLink
          to="/"
          className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5"
        >
          <h2 className="m-0 text-primary">Hotel JK</h2>
        </NavLink>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink to="/JkIndex" className="nav-item nav-link active">
              Home
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkhotelRooms"
            >
              Rooms
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelGalary"
            >
              Galary
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelabout"
            >
              About us
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelcontact"
            >
              Contact us
            </NavLink>
          </div>
          <div
            className="nav-item nav-link"
            style={{
              backgroundColor: "#b76609",
              color: "white",
              fontWeight: "bold",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            <NavLink to={"/jkbooking"} style={{ color: "white" }}>
              {" "}
              Book the Room
              <i className="fa fa-arrow-right ms-3"></i>
            </NavLink>
          </div>
        </div>
      </nav>
      <Container fluid>
        {windowWidth <= 768 ? (
          <Row>
            <Col sm={12}>
              <img
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "30vh",
                  width: "100%",
                }}
                className="responsive-image"
                src={web5}
                alt={"about us"}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={12}>
              <img
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "25vh",
                  width: "45%",
                }}
                className="responsive-image"
                src={web5}
                alt={"about us"}
              />
            </Col>
          </Row>
        )}
        ;
      </Container>

      <div style={{ backgroundColor: "white", height: "20px" }}></div>
      <Container fluid>
        <Row>
          <Col lg={6} className="order-lg-last">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      color: "black",
                      padding: "30px",
                    }}
                  >
                    JK Hotel
                  </p>

                  <ul>
                    <li>MG Road, Ramesh Chowk,,</li>
                    <li>Aurangabad-Bihar - 824101</li>
                    <li>Call Us : +91 7942602014</li>
                    <li>Email : info@jkgroupaurg.com</li>
                  </ul>
                  <br></br>
                </div>
              </div>
              <hr />
            </div>
          </Col>
          <Col lg={6} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="item-holder">
                    <figure
                      style={{
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        className="d-block w-100 responsive-image"
                        src={h5}
                        alt="First slide"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </body>
  );
};

export default JkHotelcontact;
