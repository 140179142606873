import React from "react";
import "../main/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faGoogle,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  let navigate = useNavigate();
  function changeLocation(placeToGo) {
    navigate(placeToGo, { replace: true });
    window.location.reload();
  }
  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <div
            style={{
              border: "0px solid black",

              padding: "5px 10px",
              textAlign: "center",
            }}
          >
            <p
              style={{
                fontWeight: "bold",
                marginBottom: "0px",
                color: "black",
              }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d111892.8732688573!2d84.34361462802906!3d24.685901763568044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x398cfc304de68511%3A0xed355fcc0b547095!2sJK%20Motel%20%26%20Restaurant!3m2!1d24.742101899999998!2d84.3666255!4m5!1s0x398cfc304de68511%3A0xed355fcc0b547095!2sP9R8%2BRMR%20J%20K%20Motel%20%26%20Restaurant%2C%20By%20Pass%20Road%2C%20opposite%20JK%20Hospital%2C%20Ratanua%2C%20Bihar%20824101!3m2!1d24.742101899999998!2d84.3666255!5e0!3m2!1sen!2sin!4v1693826499923!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </p>
          </div>
        </Col>
      </Row>
      <footer
        className="text-center text-white"
        style={{ backgroundColor: "rgb(241 95 95)" }}
      >
        <div className="container fluid">
          <section>
            <div className="row text-center d-flex justify-content-center pt-4">
              <div className="col-md-2">
                <h6 className="font-weight-bold">
                  <Link
                    to="/About"
                    onClick={() => changeLocation("/About")}
                    style={{ fontSize: "1rem" }}
                    className="nav-link"
                  >
                    About us
                  </Link>
                  {/* <Link
                    to={"/About"}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    About us
                  </Link> */}
                </h6>
              </div>
              <div className="col-md-2">
                <h6 className="font-weight-bold">
                  <Link
                    to="/Product"
                    onClick={() => changeLocation("/Product")}
                    style={{ fontSize: "1rem" }}
                    className="nav-link"
                  >
                    Contact us
                  </Link>

                  {/* <Link
                    to={"/Product"}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Product
                  </Link> */}
                </h6>
              </div>

              <div className="col-md-2">
                <h6 className="font-weight-bold">
                  <Link
                    to="/website"
                    onClick={() => changeLocation("/website")}
                    style={{ fontSize: "1rem" }}
                    className="nav-link"
                  >
                    Explore Group
                  </Link>
                  {/* <Link
                    to={"/website"}
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Web & Mobile App
                  </Link> */}
                </h6>
              </div>
            </div>
          </section>
          <hr className="my-2" />

          <section className="text-center mb-2">
            <a
              //   href="https://www.facebook.com/EBSSoftware/"
              className="text-white me-4"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a className="text-white me-4">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="" className="text-white me-4">
              <FontAwesomeIcon icon={faGoogle} />
            </a>
            <a
              //   href="https://www.instagram.com/ebssiliguri/"
              className="text-white me-4"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="" className="text-white me-4">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            {/* <a href="" className="text-white me-4">
              <i className="fab fa-github"></i>
            </a> */}
          </section>

          <div className="item2">
            <span style={{ paddingRight: 5 }}>Copyright </span>
            <FontAwesomeIcon icon={faCopyright} />
            <span style={{ paddingLeft: 5 }}>
              {new Date().getFullYear()} EBS Software Solution, All Rights
              Reserved.
            </span>
          </div>
        </div>
      </footer>
    </Container>
  );
};

export default Footer;
