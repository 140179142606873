import { NavLink } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Container, Row, Col } from "react-bootstrap";
import web1 from "../img/motel1.png";
import web2 from "../img/motel2.png";
import web3 from "../img/motel3.png";
import web4 from "../img/motel4.png";
import web from "../img/motel5.png";
import "../lib/owlcarousel/assets/owl.carousel.min.css";
import "../lib/animate/animate.min.css";
import "../lib/lightbox/css/lightbox.min.css";
import "../css/style.css";
import "./bootstrap.min.css";
import "../css/newcss.css";
import React, { useState, useEffect } from "react";

const JkMotelIndex = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth state on window resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <body>
      <div className="container-fluid bg-dark p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-map-marker-alt text-primary me-2"></small>
              <small>MG Road, Ramesh Chowk, Aurangabad-Bihar - 824101</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-phone-alt text-primary me-2"></small>
              <small>+91 7942602014</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-facebook-f"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-twitter"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-linkedin-in"></i>
                </NavLink>
              </div>
              <div className="btn btn-square btn-link rounded-0">
                <NavLink to="/">
                  <i className="fab fa-instagram"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <NavLink
          to="/"
          className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5"
        >
          <h2 className="m-0 text-primary">Motel JK & Restaurant</h2>
        </NavLink>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink
              to="/"
              href="index.html"
              className="nav-item nav-link active"
            >
              Home
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkMotelRooms"
            >
              Rooms
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelGalary"
            >
              Galary
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelabout"
            >
              About us
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelcontact"
            >
              Contact us
            </NavLink>
          </div>
          <div
            className="nav-item nav-link"
            style={{
              backgroundColor: "#b76609",
              color: "white",
              fontWeight: "bold",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            <NavLink to={"/jkbooking"} style={{ color: "white" }}>
              {" "}
              Book the Room
              <i className="fa fa-arrow-right ms-3"></i>
            </NavLink>
          </div>
        </div>
      </nav>

      <div
        className="container-fluid p-0 pb-4 wow fadeIn"
        data-wow-delay="0.1s"
      >
        {windowWidth <= 768 ? (
          <Carousel slide={true}>
            <Carousel.Item style={{ height: "200px" }}>
              <img
                className="d-block w-100"
                height={"200px"}
                src={web2}
                alt="Third slide"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src={web}
                height={"200px"}
                alt="First slide"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src={web1}
                height={"200px"}
                alt="Second slide"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src={web3}
                height={"200px"}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={web4}
                height={"200px"}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
        ) : (
          <Carousel slide={true}>
            <Carousel.Item
              style={{
                height: "500px",
                backgroundSize: "100% auto",
              }}
            >
              <img
                className="d-block w-100 cimg"
                height={"500px"}
                src={web2}
                alt="Third slide"
                style={{
                  position: "absolute",
                  width: "100%",
                  display: "block",
                  zIndex: "1",
                }}
              />
            </Carousel.Item>

            <Carousel.Item style={{ height: "500px" }}>
              <img
                className="d-block w-100 cimg"
                src={web}
                height={"500px"}
                alt="First slide"
                style={{
                  position: "absolute",
                  width: "100%",
                  display: "block",
                  zIndex: "1",
                }}
              />
            </Carousel.Item>

            <Carousel.Item style={{ height: "500px" }}>
              <img
                className="d-block w-100 cimg"
                src={web1}
                height={"500px"}
                alt="Second slide"
                style={{
                  position: "absolute",
                  width: "100%",
                  display: "block",
                  zIndex: "1",
                }}
              />
            </Carousel.Item>

            <Carousel.Item style={{ height: "500px" }}>
              <img
                className="d-block w-100 cimg"
                src={web3}
                height={"500px"}
                alt="Third slide"
                style={{
                  position: "absolute",
                  width: "100%",
                  display: "block",
                  zIndex: "1",
                }}
              />
            </Carousel.Item>
            <Carousel.Item style={{ height: "500px" }}>
              <img
                className="d-block w-100 cimg"
                src={web4}
                height={"500px"}
                alt="Third slide"
                style={{
                  position: "absolute",
                  width: "100%",
                  display: "block",
                  zIndex: "1",
                }}
              />
            </Carousel.Item>
          </Carousel>
        )}
        ;
        <div id="main">
          <div id="main-inner1">
            <div id="form_div">
              <div className="left">
                <h3 className="wow animated fadeInDownBig">
                  Book a Room Online
                </h3>
                <p className="wow animated fadeInUpBig">
                  Quick and easy to save your time
                </p>
              </div>

              <div className="right wow animated fadeInRight">
                <form action="book_now1.php" method="post">
                  <div className="con">
                    <p>Type of Room:</p>

                    <select name="room_type" className="box">
                      <option value="">Select a Type of Room</option>
                      <option value="Super Delux">Super Delux</option>
                      <option value="Delux">Delux</option>
                      <option value="Standard">Standard</option>
                    </select>
                  </div>

                  <div className="con">
                    <p>Check-in-date:</p>

                    <input
                      name="start_date"
                      id="start_date"
                      type="text"
                      className="box1"
                      placeholder="MM/DD/YY"
                      required="required"
                    />
                  </div>

                  <div className="con">
                    <p>Check-out-date:</p>
                    <input
                      name="end_date"
                      id="end_date"
                      type="text"
                      className="box1"
                      placeholder="MM/DD/YY"
                      required="required"
                    />
                  </div>

                  <div className="con">
                    <p>Adults:</p>
                    <input
                      type="text"
                      className="box1"
                      name="num_person"
                      required="required"
                    />
                  </div>
                  <div className="con1">
                    <input
                      name="submit"
                      type="submit"
                      className="submit"
                      value="Pay Now"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <Container fluid>
          <Row>
            <Col lg={6} className="order-lg-first iconDetails">
              {windowWidth <= 768 ? (
                <p>
                  JK Hotel represents a collection of sophisticated upscale
                  hotels. We have a wide array of rooms for our esteemed guests
                  including the standard or Deluxe room, Regular rooms, The most
                  striking feature of our hotel is its perfect location.a
                  perfect fit for contemporary travellers and the corporates. JK
                  Hotel is recognized for its spirit to serve and commitment to
                  distinctiveness, which assures better guest satisfaction.
                </p>
              ) : (
                <p>
                  <br />
                  <br />
                  <br />
                  <br />
                  JK Hotel represents a collection of sophisticated upscale
                  hotels. We have a wide array of rooms for our esteemed guests
                  including the standard or Deluxe room, Regular rooms, The most
                  striking feature of our hotel is its perfect location.a
                  perfect fit for contemporary travellers and the corporates. JK
                  Hotel is recognized for its spirit to serve and commitment to
                  distinctiveness, which assures better guest satisfaction.
                </p>
              )}
              ;
            </Col>
            <Col lg={6} className="order-lg-first">
              <img
                className="responsive-image"
                src={web4}
                alt="Right Image"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "75vh",
                  width: "100vh",
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </body>
  );
};

export default JkMotelIndex;
