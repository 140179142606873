import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import web1 from "../img/jm1.png";
import web3 from "../img/font2.png";
import web4 from "../img/jkh2.png";
import web5 from "../img/font4.png";
import web6 from "../img/font3.png";
import web7 from "../img/font1.png";
import web8 from "../img/jkm2.png";
import web9 from "../img/jkm3.png";
import web10 from "../img/jkm4.png";
import web11 from "../img/jkm5.png";
import hos1 from "../img/jkhos1.png";
import hos2 from "../img/jkhos2.png";
import hos3 from "../img/jkhos3.png";
import hos4 from "../img/jkhos4.png";
import "../main/Styles.css";
import webicon from "../img/logo.png";
import Carousel from "react-bootstrap/Carousel";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

function Intro() {
  // let navigate = useNavigate();
  // function changeLocation(placeToGo) {
  //   navigate(placeToGo, { replace: true });
  //   window.location.reload();
  // }
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth state on window resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <body>
      <div
        className="d-block px-2 py-2 text-center text-bold skippy"
        style={{ backgroundColor: "red", height: 40 }}
      >
        {windowWidth <= 768 ? (
          <div
            className="mobile-view"
            style={{
              color: "white",
              fontWeight: "bold",
              marginRight: "10px",
            }}
          >
            <a href="https://www.facebook.com/">
              <FontAwesomeIcon
                icon={faFacebook}
                style={{
                  color: "white",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              />
            </a>
            <a href="https://twitter.com/">
              <FontAwesomeIcon icon={faTwitter} style={{ color: "white" }} />
            </a>
          </div>
        ) : (
          <div className="desktop-view">
            <div style={{ float: "left" }}>
              <div>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ marginRight: "10px", color: "white" }}
                />
                <a
                  href="mailto:info@jkgroupaurg.com"
                  style={{
                    color: "white",
                    marginRight: "10px",
                    textDecoration: "none",
                  }}
                >
                  info@jkgroupaurg.com
                </a>

                <FontAwesomeIcon icon={faPhone} style={{ color: "white" }} />
                <NavLink
                  style={{ color: "white", textDecoration: "none" }}
                ></NavLink>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <p
                style={{
                  color: "white",
                  marginRight: "10px",
                  fontWeight: "bold",
                }}
              ></p>
              <a href="https://www.facebook.com/">
                <FontAwesomeIcon
                  icon={faFacebook}
                  style={{ color: "white", marginRight: "10px" }}
                />
              </a>
              <a href="https://twitter.com/">
                <FontAwesomeIcon icon={faTwitter} style={{ color: "white" }} />
              </a>
            </div>
          </div>
        )}
      </div>
      <nav
        className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5 align-items-center"
        style={{ backgroundColor: "#fbf299" }}
      >
        <a href="/" className="navbar-brand d-flex align-items-center">
          <h2
            className="m-0 "
            style={{
              fontSize: "1.30rem",
              fontWeight: "bold",
              color: "black",
              textAlign: "center",
            }}
          >
            <img
              src={webicon}
              alt="swimmer"
              width="25px"
              style={{ float: "left", marginRight: "5px" }}
            />{" "}
          </h2>
        </a>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
          <span className="sr-only"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div
            className="navbar-nav ms-auto py-4 py-lg-0"
            style={{ fontSize: "3rem" }}
          ></div>
        </div>
      </nav>
      <Container fluid style={{ backgroundColor: "#fbf299" }}>
        <Row>
          <Col sm={12}>
            <div
              className="container-block container-flexbox"
              style={{ backgroundColor: "#fbf299" }}
            >
              <div
                className="item-col-3 bg-dark item-col-padding"
                style={{ backgroundColor: "brown" }}
              >
                <Carousel slide={true}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image "
                      src={web7}
                      alt="Third slide"
                    />
                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={web3}
                      alt="First slide"
                    />
                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={web6}
                      alt="Second slide"
                    />

                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={web5}
                      alt="Third slide"
                    />

                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={web4}
                      alt="Third slide"
                    />

                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
                <NavLink
                  className="nav-link"
                  style={{ fontSize: "2rem", color: "red" }}
                  to="/JkIndex"
                >
                  <h3 style={{ color: "red" }}>Visit Motel JK</h3>
                  <p>
                    Hotel J K, set in Aurangabad - Bihar, is especially suitable
                    for tousists who travel for fun and pleasure.This hotel in
                    Aurangabad is located at a proximity of 3 km from Aurangabad
                    Bus Stand.
                  </p>
                </NavLink>
              </div>

              <div className="item-col-3 bg-dark item-col-padding">
                <Carousel slide={true}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image "
                      src={web1}
                      alt="Third slide"
                    />
                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={web8}
                      alt="First slide"
                    />
                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={web9}
                      alt="Second slide"
                    />

                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={web10}
                      alt="Third slide"
                    />

                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={web11}
                      alt="Third slide"
                    />

                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
                <NavLink
                  className="nav-link"
                  style={{ fontSize: "2rem", color: "red" }}
                  to="/JkMotelIndex"
                >
                  <h3 style={{ color: "red" }}>Visit Motel JK</h3>
                  <p>
                    JK Motel provides free internet access. This hotel in
                    Aurangabad is located at a proximity of 5 km from Aurangabad
                    Bus Stand.
                  </p>
                </NavLink>
              </div>

              <div className="item-col-3 bg-dark item-col-padding">
                <Carousel slide={true}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image "
                      src={hos1}
                      alt="Third slide"
                    />
                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={hos2}
                      alt="First slide"
                    />
                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={hos3}
                      alt="Second slide"
                    />

                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img
                      className="d-block w-100 responsive-image"
                      src={hos4}
                      alt="Third slide"
                    />

                    <Carousel.Caption
                      style={{ textAlign: "left", top: 10 }}
                    ></Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
                <h3 style={{ color: "red" }}>Explore Shree Maruti Hospital</h3>
                <p>
                  Shree Maruti Hospital is one of the leading hospital in
                  Aurangabad,Bihar. The best healthcare professionals provide
                  Comprehensive healthcare.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </body>
  );
}

export default Intro;
