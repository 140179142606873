import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../css/style.css";
import "../css/newcss.css";
import "./bootstrap.min.css";
import web5 from "../img/abtjkh.png";

const JkHotelabout = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth state on window resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <body>
      <div className="container-fluid bg-dark p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-map-marker-alt text-primary me-2"></small>
              <small>MG Road, Ramesh Chowk, Aurangabad-Bihar - 824101</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-phone-alt text-primary me-2"></small>
              <small>+91 7942602014</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-facebook-f"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-twitter"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-linkedin-in"></i>
                </NavLink>
              </div>
              <div className="btn btn-square btn-link rounded-0">
                <NavLink to="/">
                  <i className="fab fa-instagram"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <NavLink
          to="/"
          className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5"
        >
          <h2 className="m-0 text-primary">Hotel JK</h2>
        </NavLink>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink to="/JkIndex" className="nav-item nav-link active">
              Home
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkhotelRooms"
            >
              Rooms
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelGalary"
            >
              Galary
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelabout"
            >
              About us
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelcontact"
            >
              Contact us
            </NavLink>
          </div>
          <div
            className="nav-item nav-link"
            style={{
              backgroundColor: "#b76609",
              color: "white",
              fontWeight: "bold",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            <NavLink to={"/jkbooking"} style={{ color: "white" }}>
              {" "}
              Book the Room
              <i className="fa fa-arrow-right ms-3"></i>
            </NavLink>
          </div>
        </div>
      </nav>
      <Container fluid>
        {windowWidth <= 768 ? (
          <Row>
            <Col sm={12}>
              <img
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "30vh",
                  width: "100%",
                }}
                className="responsive-image"
                src={web5}
                alt={"about us"}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col sm={12}>
              <img
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "25vh",
                  width: "45%",
                }}
                className="responsive-image"
                src={web5}
                alt={"about us"}
              />
            </Col>
          </Row>
        )}
        ;
      </Container>

      <Container fluid>
        <Row>
          <div
            className="container h-100"
            style={{ border: "1px", borderColor: "green" }}
          >
            <div
              className="row h-100 justify-content-center align-items-center"
              style={{ border: "1px", borderColor: "green" }}
            >
              <div
                className="col-12 col-md-10 col-lg-12"
                style={{ border: "1px", borderColor: "green" }}
              >
                <h2>About Us</h2>
                <br />
                <p style={{ textAlign: "justify", fontSize: "20px" }}>
                  Welcome to the J K Hotel, located in the center of Aurangabad,
                  Bihar, where convenience and luxury meet. Our deluxe category
                  hotel is the ideal getaway for both business and leisure
                  travelers looking for an amazing stay, and it is located in a
                  prime area. You can make the most of your time in Aurangabad
                  thanks to our hotel's strategic location, which offers quick
                  access to the main business and tourist destinations in the
                  area. Our central location makes for a seamless and enriching
                  experience, whether you're here for business meetings or to
                  explore the historical treasures.
                </p>
                <br />
                <p style={{ textAlign: "justify", fontSize: "20px" }}>
                  Savor the comforts of our well thought-out rooms, where
                  sophisticated design and contemporary conveniences coexist
                  harmoniously. High-speed WiFi is available in every room,
                  making it simple for you to stay connected. Savor the comforts
                  of air-conditioned rooms that guarantee a restful night's
                  sleep and offer a pleasant break from the busy city. Every
                  room has a television for your entertainment, with a selection
                  of channels to choose from. Our in-room entertainment options
                  suit your needs, whether you'd rather relax with your favorite
                  shows or keep up to date on the latest events.
                </p>
                <br />
                <p style={{ textAlign: "justify", fontSize: "20px" }}>
                  At Hotel JK, we put your safety first. We are proud to offer
                  our visitors a safe and secure environment. Strong safety
                  precautions are part of our dedication to your wellbeing, so
                  you can unwind and enjoy your visit in peace of mind.
                  Experience a delightful fusion of safety, comfort, and
                  convenience at Hotel JK, your luxurious sanctuary in
                  Aurangabad, Bihar. We hope to go above and beyond your
                  expectations and create a genuinely unforgettable stay,
                  whether you are here on business or for pleasure. Welcome to
                  Hotel JK, a place where every last detail has been carefully
                  considered to ensure your pleasure.
                </p>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </body>
  );
};

export default JkHotelabout;
