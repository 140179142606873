import React, { useState, useEffect } from "react";
import h3 from "../img/mdelux.png";
import h4 from "../img/mroom1.png";
import h5 from "../img/mroom2.png";
import web5 from "../img/jkmotel.png";
import web6 from "../img/motel6.png";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../css/style.css";

const JkMotelRooms = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth state on window resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let alText = "Photo";
  return (
    <body>
      <div className="container-fluid bg-dark p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-map-marker-alt text-primary me-2"></small>
              <small>MG Road, Ramesh Chowk, Aurangabad-Bihar - 824101</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-phone-alt text-primary me-2"></small>
              <small>+91 7942602014</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-facebook-f"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-twitter"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-linkedin-in"></i>
                </NavLink>
              </div>
              <div className="btn btn-square btn-link rounded-0">
                <NavLink to="/">
                  <i className="fab fa-instagram"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <NavLink
          to="/JkIndex"
          className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5"
        >
          <h2 className="m-0 text-primary">Motel JK & Restaurant</h2>
        </NavLink>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink to="/JkIndex" className="nav-item nav-link active">
              Home
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkhotelRooms"
            >
              Rooms
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelGalary"
            >
              Galary
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelabout"
            >
              About us
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelcontact"
            >
              Contact us
            </NavLink>
          </div>
          <div
            className="nav-item nav-link"
            style={{
              backgroundColor: "#b76609",
              color: "white",
              fontWeight: "bold",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            <NavLink to={"/jkbooking"} style={{ color: "white" }}>
              {" "}
              Book the Room
              <i className="fa fa-arrow-right ms-3"></i>
            </NavLink>
          </div>
        </div>
      </nav>
      {windowWidth <= 768 ? (
        <Col sm={12}>
          <img
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
            className="responsive-image"
            src={web5}
            alt={alText}
          />
        </Col>
      ) : (
        <img
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "584px",
          }}
          className="responsive-image"
          src={web5}
          alt={alText}
        />
      )}

      <div style={{ backgroundColor: "white", height: "20px" }}></div>
      <Container fluid>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",

                        color: "#009fdf",
                        textAlign: "center",
                        borderColor: "white",
                        fontFamily: "Gotham, Helvetica, Arial, sans-serif",
                      }}
                    >
                      JK Motel- Super Delux
                    </p>
                    <div
                      style={{ backgroundColor: "white", height: "20px" }}
                    ></div>
                  </div>
                  <div className="item-holder">
                    <figure
                      style={{
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        className="d-block w-100 responsive-image"
                        src={h5}
                        alt="First slide"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#009fdf",
                        textAlign: "right",
                        borderColor: "white",
                      }}
                    >
                      Room Rent : 3000/- Per Night
                    </p>

                    <NavLink to={"/jkbooking"}>
                      <div
                        style={{
                          backgroundColor: "white",
                          height: "20px",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Book Now
                      </div>
                    </NavLink>
                    <br />

                    <p
                      style={{
                        fontSize: "20px",
                        lineHeight: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Amenities
                    </p>
                    <br />
                    <p>1. Television</p>
                    <p>2. 24 Hours Free WiFi</p>
                    <p>3. Power Backup</p>
                    <p>4. Intercom</p>
                    <p>5. Wardrobe</p>
                    <p>6. Laundry Service</p>
                    <p>7. Doctor On call</p>
                    <p>8. Air conditioning - Climate Control</p>
                    <p>9. Eco-friendly</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="container-container">
              <div className="row">
                <p style={{ textAlign: "justify", fontSize: "15px" }}>
                  Indulge in the epitome of luxury with our Super Deluxe Rooms
                  at Motel JK. These exclusive accommodations redefine the
                  meaning of comfort and style, offering a lavish retreat for
                  our discerning guests. Our Super Deluxe Rooms boast a spacious
                  250 square feet of opulence, providing you with an expansive
                  haven to unwind and relax. From the moment you step in, you'll
                  be greeted by a sense of grandeur and sophistication that sets
                  the tone for an unforgettable stay. Experience comfort at its
                  finest with individually controlled air-conditioning in each
                  Super Deluxe Room. Regardless of the season, you can create
                  your perfect haven, ensuring that your stay is always
                  accompanied by the ideal temperature and a refreshing
                  ambiance.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ backgroundColor: "white", height: "20px" }}></div>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#009fdf",
                        textAlign: "center",
                        borderColor: "white",
                        fontFamily: "Gotham, Helvetica, Arial, sans-serif",
                      }}
                    >
                      JK Motel- Delux
                    </p>
                    <div
                      style={{ backgroundColor: "white", height: "20px" }}
                    ></div>

                    <p
                      style={{
                        fontSize: "15px",
                        lineHeight: "20px",
                      }}
                    ></p>
                  </div>
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          className="d-block w-100 responsive-image"
                          src={h4}
                          alt="First slide"
                        />
                        <div
                          style={{ backgroundColor: "white", height: "20px" }}
                        ></div>
                        <div className="text-area">
                          <div className="content-text">
                            <p
                              style={{
                                fontSize: "13px",
                                lineHeight: "20px",
                                textAlign: "justify",
                              }}
                            ></p>
                          </div>
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#009fdf",
                        textAlign: "right",
                        borderColor: "white",
                      }}
                    >
                      Room Rent : 2500/- Per Night
                    </p>

                    <div
                      style={{
                        backgroundColor: "white",
                        height: "20px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      Book Now
                    </div>

                    <br />
                    <p
                      style={{
                        fontSize: "20px",
                        lineHeight: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Amenities
                    </p>
                    <br />
                    <p>1. Television</p>
                    <p>2. 24 Hours Free WiFi</p>
                    <p>3. Power Backup</p>
                    <p>4. Intercom</p>
                    <p>5. Wardrobe</p>
                    <p>6. Laundry Service</p>
                    <p>7. Doctor On call</p>
                    <p>8. Air conditioning - Climate Control</p>
                    <p>9. Eco-friendly</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="container-container">
              <div className="row">
                <p style={{ textAlign: "justify", fontSize: "15px" }}>
                  Our Deluxe Rooms at Motel Jk. Thoughtfully designed to offer a
                  perfect blend of functionality and elegance, these rooms
                  provide a delightful retreat for our valued guests. Relax in
                  the peace and quiet of our 200 square foot Deluxe Rooms, which
                  offer luxurious amenities. The room is thoughtfully designed
                  to maximize every square foot, even with its compact size,
                  guaranteeing a comfortable and productive stay. Enjoy a
                  personalized entertainment experience with a sleek television
                  provided in each Deluxe Room. Whether you're winding down with
                  your favorite shows or catching up on the latest news, our
                  in-room entertainment ensures you have a range of options at
                  your disposal.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ backgroundColor: "white", height: "20px" }}></div>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <NavLink
                  to={"/JkMotelbooking"}
                  style={{ textDecoration: "none" }}
                >
                  <div className="card" style={{ border: "0px" }}>
                    <div className="card-title">
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#009fdf",
                          textAlign: "center",
                          borderColor: "white",
                          fontFamily: "Gotham, Helvetica, Arial, sans-serif",
                        }}
                      >
                        JK Motel- Standard
                      </p>
                      <div
                        style={{ backgroundColor: "white", height: "20px" }}
                      ></div>

                      <p
                        style={{
                          fontSize: "15px",
                          lineHeight: "20px",
                        }}
                      ></p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",

                            overflow: "hidden",
                          }}
                        >
                          <img
                            className="d-block w-100 responsive-image"
                            src={h3}
                            alt="First slide"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#009fdf",
                        textAlign: "right",
                        borderColor: "white",
                      }}
                    >
                      Room Rent : 2500/- Per Night
                    </p>
                    <NavLink
                      to={"/JkMotelbooking"}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          height: "20px",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Book Now
                      </div>
                    </NavLink>
                    <br />
                    <p
                      style={{
                        fontSize: "20px",
                        lineHeight: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Amenities
                    </p>
                    <br />
                    <p>1. Television</p>
                    <p>2. 24 Hours Free WiFi</p>
                    <p>3. Power Backup</p>
                    <p>4. Intercom</p>
                    <p>5. Wardrobe</p>
                    <p>6. Laundry Service</p>
                    <p>7. Doctor On call</p>
                    <p>8. Air conditioning - Climate Control</p>
                    <p>9. Eco- Friendly</p>
                    <p>
                      Our Deluxe Rooms are spacious with the scenic window view.
                      It has the capacity of accommodating more than 2 people.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="container-container">
              <div className="row">
                <p style={{ textAlign: "justify", fontSize: "15px" }}>
                  our Standard Rooms at Motel JK. Designed for both efficiency
                  and relaxation, these cozy retreats provide a welcoming haven
                  for our guests seeking a comfortable stay. Our Standard Rooms,
                  though cozy at 150 square feet, exude an understated elegance
                  that ensures your stay is both comfortable and efficient.
                  Embracing a minimalist design, these rooms maximize space to
                  create an inviting atmosphere for a restful night's sleep.The
                  Standard Room reflects a thoughtful design that prioritizes
                  functionality without compromising on comfort. From the layout
                  to the furnishings, every element is carefully chosen to
                  optimize the space, creating a cozy retreat that feels like a
                  home away from home.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{ backgroundColor: "white", height: "20px" }}></div>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <NavLink
                  to={"/JkMotelbooking"}
                  style={{ textDecoration: "none" }}
                >
                  <div className="card" style={{ border: "0px" }}>
                    <div className="card-title">
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#009fdf",
                          textAlign: "center",
                          borderColor: "white",
                          fontFamily: "Gotham, Helvetica, Arial, sans-serif",
                        }}
                      >
                        JK Motel- Wedding & Conference
                      </p>
                      <div
                        style={{ backgroundColor: "white", height: "20px" }}
                      ></div>

                      <p
                        style={{
                          fontSize: "15px",
                          lineHeight: "20px",
                        }}
                      ></p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",

                            overflow: "hidden",
                          }}
                        >
                          <img
                            className="d-block w-100 responsive-image"
                            src={web6}
                            alt="First slide"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <NavLink
                      to={"/JkMotelbooking"}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          height: "20px",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Book Now
                      </div>
                    </NavLink>
                    <br />
                    <p
                      style={{
                        fontSize: "20px",
                        lineHeight: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Wedding & Conference
                    </p>
                    <br />
                    <p style={{ textAlign: "justify" }}>
                      Welcome to Motel JK, where events come to life and moments
                      turn into memories. A versatile conference and wedding
                      hall, a hallmark of sophistication and functionality that
                      turns events into extraordinary experiences, is proudly
                      presented by our prestigious establishment.
                    </p>
                    <br />
                    <p style={{ fontWeight: "bolder" }}>
                      Versatility Unveiled:
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      Enter a world where adaptability is valued highly. Our
                      conference and wedding hall is a flexible area that can be
                      tailored to meet the specific needs of any occasion. Our
                      venue can accommodate everything from elegant weddings to
                      business conferences. Your vision can be accurately and
                      elegantly painted on it.
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                      State-of-the-Art Facilities:
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      Modern facilities and state-of-the-art technology can
                      elevate your event. With the most advanced audio-visual
                      equipment available, our conference room guarantees
                      flawless communication and crystal-clear presentations.
                      Our hall offers the ideal setting for success, whether
                      it's a business symposium or a sincere vow exchange.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </body>
  );
};

export default JkMotelRooms;
