import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "./components/Footer";
import Intro from "./components/intro";
import JkIndex from "./jkhotel";
import JkhotelRooms from "./jkhotel/jkhotelroom";
import Jkbooking from "./jkhotel/jkbooking";
import JkHotelabout from "./jkhotel/aboutjkhotel";
import JkHotelGalary from "./jkhotel/galary";
import JkHotelcontact from "./jkhotel/jkcontactus";
import JkMotelIndex from "./jkmotel";
import JkMotelbooking from "./jkmotel/jkmotelbooking";
import JkMotelRooms from "./jkmotel/jkmotelroom";

const App = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wel-come Offcial Web site of JK Group</title>
        <meta name="description" content="" />
        <meta name="description" content="" />
        <script type="text/babel" src="../src/lib/wow/wow.min.js"></script>
        <script
          type="text/babel"
          src="../src/lib/easing/easing.min.js"
        ></script>
        <script
          type="text/babel"
          src="../src/lib/waypoints/waypoints.min.js"
        ></script>
        <script
          type="text/babel"
          src="../src/lib/counterup/counterup.min.js"
        ></script>
        <script
          type="text/babel"
          src="../src/lib/owlcarousel/owl.carousel.min.js"
        ></script>
        <script
          type="text/babel"
          src="../src/lib/isotope/isotope.pkgd.min.js"
        ></script>
        <script
          type="text/babel"
          src="../src/lib/lightbox/js/lightbox.min.js"
        ></script>
        <script type="text/babel" src="../src/js/main.js"></script>

        <script
          type="text/babel"
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js"
        ></script>
      </Helmet>

      <div>
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/JkIndex" element={<JkIndex />} />
          <Route path="/JkhotelRooms" element={<JkhotelRooms />} />
          <Route path="/Jkbooking" element={<Jkbooking />} />
          <Route path="/JkHotelabout" element={<JkHotelabout />} />
          <Route path="/JkHotelGalary" element={<JkHotelGalary />} />
          <Route path="/JkHotelcontact" element={<JkHotelcontact />} />
          <Route path="/JkMotelIndex" element={<JkMotelIndex />} />
          <Route path="/JkMotelRooms" element={<JkMotelRooms />} />
          <Route path="/JkMotelbooking" element={<JkMotelbooking />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
