import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../css/style.css";
import "../css/newcss.css";
import "./bootstrap.min.css";
import h1 from "../img/delux.png";
import h2 from "../img/new5.png";
import h3 from "../img/font2.png";
import h4 from "../img/new1.png";
import h5 from "../img/gfont1.png";
import h6 from "../img/gfont3.png";
import h7 from "../img/new3.png";
import h8 from "../img/jkh2.png";
import h9 from "../img/new2.png";
import h10 from "../img/jkh1.png";
import h11 from "../img/new4.png";
import h12 from "../img/font4.png";

const JkHotelGalary = () => {
  let alText = "Photo";
  return (
    <body>
      <div className="container-fluid bg-dark p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-map-marker-alt text-primary me-2"></small>
              <small>MG Road, Ramesh Chowk, Aurangabad-Bihar - 824101</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-phone-alt text-primary me-2"></small>
              <small>+91 7942602014</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-facebook-f"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-twitter"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-linkedin-in"></i>
                </NavLink>
              </div>
              <div className="btn btn-square btn-link rounded-0">
                <NavLink to="/">
                  <i className="fab fa-instagram"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <NavLink
          to="/"
          className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5"
        >
          <h2 className="m-0 text-primary">Hotel JK</h2>
        </NavLink>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink to="/" className="nav-item nav-link active">
              Home
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkhotelRooms"
            >
              Rooms
            </NavLink>
            <NavLink to="/JkHotelGalary" className="nav-item nav-link">
              Galary
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelabout"
            >
              About us
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelcontact"
            >
              Contact us
            </NavLink>
          </div>
          <div
            className="nav-item nav-link"
            style={{
              backgroundColor: "#b76609",
              color: "white",
              fontWeight: "bold",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            <NavLink to={"/jkbooking"} style={{ color: "white" }}>
              {" "}
              Book the Room
              <i className="fa fa-arrow-right ms-3"></i>
            </NavLink>
          </div>
        </div>
      </nav>

      <Container fluid>
        <Row>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h1} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h2} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h3} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h4} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h5} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h6} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h7} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h8} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h9} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h10} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h11} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card">
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          height: "208px",
                          overflow: "hidden",
                        }}
                      >
                        <img src={h12} alt={alText} />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>

              <br></br>
            </div>
          </Col>
        </Row>
      </Container>
    </body>
  );
};

export default JkHotelGalary;
