import React, { useState, useEffect } from "react";
import h3 from "../img/standard.png";
import h4 from "../img/delux.png";
import h5 from "../img/superd.png";
import web5 from "../img/carousel-1.jpg";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../css/style.css";

const JkhotelRooms = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update windowWidth state on window resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let alText = "Photo";
  return (
    <body>
      <div className="container-fluid bg-dark p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-map-marker-alt text-primary me-2"></small>
              <small>MG Road, Ramesh Chowk, Aurangabad-Bihar - 824101</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-phone-alt text-primary me-2"></small>
              <small>+91 7942602014</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-facebook-f"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-twitter"></i>
                </NavLink>
              </div>

              <div className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary">
                <NavLink to="/">
                  <i className="fab fa-linkedin-in"></i>
                </NavLink>
              </div>
              <div className="btn btn-square btn-link rounded-0">
                <NavLink to="/">
                  <i className="fab fa-instagram"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <NavLink
          to="/JkIndex"
          className="navbar-brand d-flex align-items-center border-end px-4 px-lg-5"
        >
          <h2 className="m-0 text-primary">Hotel JK</h2>
        </NavLink>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink to="/JkIndex" className="nav-item nav-link active">
              Home
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkhotelRooms"
            >
              Rooms
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelGalary"
            >
              Galary
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelabout"
            >
              About us
            </NavLink>
            <NavLink
              className="nav-link"
              style={{ fontSize: "1rem", color: "black" }}
              to="/JkHotelcontact"
            >
              Contact us
            </NavLink>
          </div>
          <div
            className="nav-item nav-link"
            style={{
              backgroundColor: "#b76609",
              color: "white",
              fontWeight: "bold",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            <NavLink to={"/jkbooking"} style={{ color: "white" }}>
              {" "}
              Book the Room
              <i className="fa fa-arrow-right ms-3"></i>
            </NavLink>
          </div>
        </div>
      </nav>
      {windowWidth <= 768 ? (
        <Col sm={12}>
          <img
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "400px",
            }}
            className="responsive-image"
            src={web5}
            alt={alText}
          />
        </Col>
      ) : (
        <img
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "584px",
          }}
          className="responsive-image"
          src={web5}
          alt={alText}
        />
      )}

      <div style={{ backgroundColor: "white", height: "20px" }}></div>
      <Container fluid>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",

                        color: "#009fdf",
                        textAlign: "center",
                        borderColor: "white",
                        fontFamily: "Gotham, Helvetica, Arial, sans-serif",
                      }}
                    >
                      JK Hotel- Super Delux
                    </p>
                    <div
                      style={{ backgroundColor: "white", height: "20px" }}
                    ></div>
                  </div>
                  <div className="item-holder">
                    <figure
                      style={{
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        className="d-block w-100 responsive-image"
                        src={h5}
                        alt="First slide"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#009fdf",
                        textAlign: "right",
                        borderColor: "white",
                      }}
                    >
                      Room Rent : 3000/- Per Night
                    </p>

                    <NavLink to={"/jkbooking"}>
                      <div
                        style={{
                          backgroundColor: "white",
                          height: "20px",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Book Now
                      </div>
                    </NavLink>
                    <br />

                    <p
                      style={{
                        fontSize: "20px",
                        lineHeight: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Amenities
                    </p>
                    <br />
                    <p>1. Television</p>
                    <p>2. 24 Hours Free WiFi</p>
                    <p>3. Power Backup</p>
                    <p>4. Intercom</p>
                    <p>5. Wardrobe</p>
                    <p>6. Laundry Service</p>
                    <p>7. Doctor On call</p>
                    <p>8. Air conditioning - Climate Control</p>
                    <p>9. Eco-friendly</p>
                    <p>
                      10. These rooms are 250 sq. ft., in size and accommodate
                      up to 3 guests.
                    </p>
                    <p>
                      Bright and stylish ,with elegant furnishings and well
                      decorated eye catching light. Super Deluxe rooms are a
                      perfect combination of functionality,comfort with a touch
                      of warmth.
                    </p>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <p
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#009fdf",
                        textAlign: "center",
                        borderColor: "white",
                        fontFamily: "Gotham, Helvetica, Arial, sans-serif",
                      }}
                    >
                      JK Hotel- Delux
                    </p>
                    <div
                      style={{ backgroundColor: "white", height: "20px" }}
                    ></div>

                    <p
                      style={{
                        fontSize: "15px",
                        lineHeight: "20px",
                      }}
                    ></p>
                  </div>
                  <div className="item-holder">
                    <div className="image-box">
                      <figure
                        style={{
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          className="d-block w-100 responsive-image"
                          src={h4}
                          alt="First slide"
                        />
                        <div
                          style={{ backgroundColor: "white", height: "20px" }}
                        ></div>
                        <div className="text-area">
                          <div className="content-text">
                            <p
                              style={{
                                fontSize: "13px",
                                lineHeight: "20px",
                                textAlign: "justify",
                              }}
                            >
                              <a
                                href="/Hotelsof"
                                style={{
                                  textDecoration: "none",
                                  color: "rgb(118, 142, 16)",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                              </a>
                            </p>
                          </div>
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#009fdf",
                        textAlign: "right",
                        borderColor: "white",
                      }}
                    >
                      Room Rent : 2500/- Per Night
                    </p>

                    <div
                      style={{
                        backgroundColor: "white",
                        height: "20px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      Book Now
                    </div>

                    <br />
                    <p
                      style={{
                        fontSize: "20px",
                        lineHeight: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Amenities
                    </p>
                    <br />
                    <p>1. Television</p>
                    <p>2. 24 Hours Free WiFi</p>
                    <p>3. Power Backup</p>
                    <p>4. Intercom</p>
                    <p>5. Wardrobe</p>
                    <p>6. Laundry Service</p>
                    <p>7. Doctor On call</p>
                    <p>8. Air conditioning - Climate Control</p>
                    <p>9. Eco-friendly</p>
                    <p>
                      10.These rooms offer 200 sq. ft. of space that can
                      accommodate up to 3 guests.
                    </p>
                    <p>
                      Our Deluxe Rooms are spacious with the scenic window view.
                      It has the capacity of accommodating more than 2 people.
                    </p>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="order-lg-first">
            <div className="container-container">
              <div className="row">
                <NavLink to={"/Jkbooking"} style={{ textDecoration: "none" }}>
                  <div className="card" style={{ border: "0px" }}>
                    <div className="card-title">
                      <p
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          color: "#009fdf",
                          textAlign: "center",
                          borderColor: "white",
                          fontFamily: "Gotham, Helvetica, Arial, sans-serif",
                        }}
                      >
                        JK Hotel- Standard
                      </p>
                      <div
                        style={{ backgroundColor: "white", height: "20px" }}
                      ></div>

                      <p
                        style={{
                          fontSize: "15px",
                          lineHeight: "20px",
                        }}
                      ></p>
                    </div>
                    <div className="item-holder">
                      <div className="image-box">
                        <figure
                          style={{
                            width: "100%",

                            overflow: "hidden",
                          }}
                        >
                          <img
                            className="d-block w-100 responsive-image"
                            src={h3}
                            alt="First slide"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </Col>
          <Col lg={6} className="order-lg-last">
            <div className="container-container">
              <div className="row">
                <div className="card" style={{ border: "0px" }}>
                  <div className="card-title">
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#009fdf",
                        textAlign: "right",
                        borderColor: "white",
                      }}
                    >
                      Room Rent : 2500/- Per Night
                    </p>
                    <NavLink
                      to={"/Jkbooking"}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        style={{
                          backgroundColor: "white",
                          height: "20px",
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        Book Now
                      </div>
                    </NavLink>
                    <br />
                    <p
                      style={{
                        fontSize: "20px",
                        lineHeight: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Amenities
                    </p>
                    <br />
                    <p>1. Television</p>
                    <p>2. 24 Hours Free WiFi</p>
                    <p>3. Power Backup</p>
                    <p>4. Intercom</p>
                    <p>5. Wardrobe</p>
                    <p>6. Laundry Service</p>
                    <p>7. Doctor On call</p>
                    <p>8. Air conditioning - Climate Control</p>
                    <p>9. Eco- Friendly</p>
                    <p>
                      Our Deluxe Rooms are spacious with the scenic window view.
                      It has the capacity of accommodating more than 2 people.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </body>
  );
};

export default JkhotelRooms;
